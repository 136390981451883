.app-container {
	background-color: var(--neutral-10);
}

.preview-children {
	height: calc(100vh);
	overflow-y: auto;
	margin-top: 72px;
	max-width: 1440px;
	padding: 50px 0px 50px 0px;
	scroll-behavior: smooth;
	margin: 0 auto;
	padding-top: 50px !important;
}

.preview_img_container > img {
	width: 290px;
	height: 103px;
	object-fit: contain;
	cursor: pointer;
}

.preview-module-children {
	overflow-y: auto;
	scroll-behavior: smooth;
	height: 100%;
}
@media (min-width: 768px) {
	.preview-children {
		padding: 50px 5% 50px 5%;
	}
}