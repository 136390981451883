.shared-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	padding: 30px 0px;
	background-color: var(--dark-bg-text-color);
}

.shared-children {
	min-height: calc(100vh);
	margin-top: 72px;
	max-width: 1440px;
	padding: 50px 0px 0px 0px;
	scroll-behavior: smooth;
	margin: 0 auto;
	padding-top: 200px !important;
}

.shared_img_container > img {
	width: 290px;
	max-width: 100%;
	height: 103px;
	object-fit: contain;
	cursor: pointer;
}

.shared-module-children {
	scroll-behavior: smooth;
	height: 100%;
}
.center-nav-menu ul {
	justify-content: center !important;
}
@media (min-width: 768px) {
	.shared-children {
		padding: 50px 5% 0px 5%;
	}
}
