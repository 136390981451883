.res-score-card--wrapper {
	height: fit-content;
	/* height: 288px; */
	width: 180px;
	border-radius: 4px;
	background: var(--dark-bg-text-color);
	border: 1px solid var(--border-stoke);
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px 24px 24px 24px;
}

.card-title {
	font-weight: 500;
	font-size: 1rem;
	line-height: 24px;
	color: #172b4d;
	margin-bottom: 28px;
}

.res-score-card--container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}
.res-score-card {
	background-color: white;
	height: 120px;
	width: 120px;
	box-shadow: 0px 9.88026px 14.8204px rgba(9, 30, 66, 0.15);
	border-radius: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}

.res-score-card svg {
	height: 120px;
	width: 120px;
	position: absolute;
	transform: rotate(-90deg);
}
.res-score-card svg circle {
	fill: transparent;
	stroke: var(--light-yellow);
	stroke-width: 6px;
	stroke-linecap: round;
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
	transition: stroke-dashoffset 4s ease-in-out 0s;
}

.score-value {
	line-height: 1;
	font-weight: bold;
	font-size: 29.6408px;
	color: var(--text-color);
}

.score-value--overall {
	font-weight: 450;
	font-size: 12.3503px;
	text-align: center;
	color: #344563;
}

.score-statement {
	margin-top: 32px;
}
.score-statement p {
	padding-left: 5px;
	font-weight: 450;
	font-size: 0.86rem;
	line-height: 16px;
	color: #344563;
	position: relative;
}
.score-statement p span {
	font-size: 0.86rem;
	font-weight: 600;
}
.score-statement p::after {
	content: "";
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 7px;
	top: 45%;
	left: -10px;
	transform: translateY(-50%);
	background: #8993a4;
}
.score-statement p:nth-child(2):after {
	background: #b3d4ff;
}

.res-score-card--container .appraiser-score-guage {
	position: absolute;
	transform: rotate(-90deg);
	top: -91px;
	left: -89px;
	z-index: 1;
}

.res-score-card--container .appraiser-score-guage circle {
	fill: transparent;
	stroke: #b3d4ff;
	stroke-width: 6px;
	stroke-linecap: round;
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
}
