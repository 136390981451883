.rse-radio label {
	text-transform: capitalize;
	font-size: 1rem;
	font-weight: 500;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position: absolute;
	opacity: 0;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
	height: 20px;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: var(--text-color);
	margin: 8px 0px;
	padding: 0px;
	padding-left: 25px;
	text-align: center;
	line-height: 18px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
	content: "";
	position: absolute;
	left: 0;
	top: 46%;
	transform: translateY(-50%);
	width: 12px;
	height: 12px;
	border: 2px solid #dfe1e6;
	border-radius: 100%;
	background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
	content: "";
	width: 12px;
	height: 12px;
	position: absolute;
	top: 46%;
	transform: translateY(-50%) !important;
	left: 0px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	background: rgba(255, 255, 255, 0.0001);
	position: absolute;
	border: 4px solid var(--primary);
}

[type="radio"]:checked:disabled + label:after,
[type="radio"]:not(:checked):disabled + label:after {
	content: "";
	width: 12px;
	height: 12px;
	background: #a5adba;
	position: absolute;
	top: 46%;
	transform: translateY(-50%);
	left: 0px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	position: absolute;
	border: 4px solid var(--disabled);
	cursor: not-allowed;
}

[type="radio"]:disabled,
[type="radio"]:disabled + label {
	cursor: not-allowed;
}

[type="radio"]:not(:checked):disabled + label:after,
[type="radio"]:disabled:not(:checked) + label:before {
	content: "";
	width: 12px;
	height: 12px;
	background: var(--disabled);
	position: absolute;
	top: 46%;
	transform: translateY(-50%);
	left: 0px;
	border-radius: 100%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	position: absolute;
	border: 4px solid var(--disabled);
	cursor: not-allowed;
}

[type="radio"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}
[type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}
