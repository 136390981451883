.searchable_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
	border: 1px solid #dfe1e6;
	border-radius: 4px;
}

.searchable_container input {
	width: 95%;
	border: 0;
	padding-left: 2%;
	height: 100%;
	font-weight: 450;
	font-size: 1rem;
	text-transform: capitalize;
	color: #253858;
	outline: none !important;
}

.searchable_container input:focus {
	border: 1px solid #fff;
}
.searchable_container input::placeholder {
	color: var(--gray);
}

.searchable_icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 5%;
}

.searchable_dropdown {
	width: 100%;
	cursor: pointer;
	position: absolute;
	background: #fff;
	overflow-y: scroll;
	max-height: 100px;
	z-index: 1;
}

.searchable_dropdown p {
	text-transform: capitalize;
	margin-left: 10px;
}
