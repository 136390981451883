.bed-sapce-container {
	position: relative;
	width: 100%;
	height: fit-content;
}

.bed-space-card {
	flex-direction: column;
	padding: 16px 60px;
	background-color: var(--white-color);
	border: 1px solid var(--border-stoke);
	border-radius: 4px;
	cursor: pointer;
	transition: 0.3s ease-in-out;
}

.bed-space-card:hover {
	box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15),
		0px 0px 1px rgba(9, 30, 66, 0.31);
}

.room-disabled-container {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border-radius: 4px;
	transition: 0.3s ease-in-out;
}

.room-disabled {
	background-color: var(--neutral-light);
}
.bed-space-numeration {
	font-size: 1.36rem;
	color: var(--text-color);
	margin-top: 21.82px;
}
.bed-space-price {
	font-size: 1.14rem;
	color: var(--darker-gray);
	margin-top: 21.82px;
}

#bed_space_svg {
	margin-top: 20px;
}

.room-card-selected-bg {
	background-color: var(--active-bg-link);
}

.room-card-selected {
	border: 1px solid var(--blue-custom);
}

.room-card-selected-svg path {
	stroke: var(--blue-custom);
}
