.res-side-menu--wrapper {
	display: flex;
	flex-direction: row-reverse;
}

.red-side-menu {
	width: 248px;
	background: #fff;
	padding: 20px 0;
	height: 100vh;
	z-index: 300;
}
.red-side-menu-head > button {
	background: none;
}

.red-side-menu-head img {
	width: 80%;
}
.red-logo-side {
	margin-left: 10px;
}

.red-side-menu-head {
	margin: 0 25px;
}

.red-module-title {
	font-style: normal;
	font-weight: 500;
	font-size: 0.86rem;
	line-height: 12px;
	color: var(--light-text-color);
	margin-bottom: 24px;
}

.red-side-module {
	padding: 0 24px;
	padding-top: 36px;
	max-height: 100%;
	overflow-y: scroll;
}

.red-side-module ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.red-side-module ul li {
	margin-bottom: 24px;
	font-style: normal;
	font-weight: bold;
	font-size: 1rem;
	line-height: 20px;
	color: var(--text-color);
	opacity: 0.75;
	cursor: pointer;
}

.red-side-module ul li:hover {
	color: var(--primary-hover);
}

.red-side-module ul li:focus {
	color: var(--primary) !important;
}

.red-side-module-disabled,
.red-side-module-disabled:hover {
	color: #a5adba !important;
}

.red-side-module ul li:last-child {
	margin-bottom: 12px;
}

.activate_nav {
	color: var(--primary) !important;
	background: var(--disabled);
	border-radius: 2px;
	padding: 8px 12px;
}

.inactive_nav {
	border-radius: 2px;
	padding: 8px 12px;
}

@media (max-width: 575px) {
	.red-side-menu {
		width: 360px;
	}

	.red-module-title {
		font-size: 1rem;
		line-height: 16px;
	}

	.red-side-menu-head {
		margin: 0 21px;
	}

	.red-side-module {
		padding: 0 20px;
		padding: 44px;
	}
}
