.res-success-tag,
.res-error-tag,
.res-pending-tag,
.res-progress-tag {
	background: var(--light-green);
	padding: 2px 8px;
	border-radius: 1px;
	font-style: normal;
	font-weight: 450;
	font-size: 0.86rem;
	line-height: 16px;
	color: #155457;
	display: inline-block;
	height: 20px;
	text-transform: capitalize;
}

.res-rejected-tag {
	font-weight: 450;
	padding: 2px 8px;
	height: 20px;
	padding: 5px;
	background: #ffebe6;
	border-radius: 1px;
	color: var(--text-color);
	font-size: 0.86rem;
	text-transform: capitalize;
}

.res-warn-tag {
	display: inline-block;
	height: 20px;
	font-weight: 450;
	background: #fff0b3;
	padding: 2px 8px;

	font-style: normal;
	border-radius: 1px;
	font-size: 0.86rem;
	line-height: 16px;
	color: var(--text-color);
	text-transform: capitalize;
}

.res-unapproved-tag {
	font-weight: 450;
	height: 20px;
	padding: 2px 8px;
	padding: 5px;
	color: var(--text-color);
	background: #ffebe6;
	text-transform: capitalize;
}

.res-notsubmitted-tag {
	font-weight: 450;
	height: 20px;
	padding: 2px 8px;
	padding: 5px;
	color: var(--text-color);
	background: #deebff;
	text-transform: capitalize;
}
