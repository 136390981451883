.sec-link {
	padding: 0;
	border: 0;
	background: transparent;
	color: var(--text-green);
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
}

.primary-link {
	color: var(--primary);
}

.danger-link {
	color: var(--danger);
}