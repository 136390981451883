.box-input-item {
	border: 1px solid var(--border-stoke);
	padding: 15px 0px;
	cursor: pointer;
}

.box-input-item > * {
	font-weight: 500 !important;
	width: 100%;
}

.active-box {
	background: rgba(235, 236, 240, 1);
}
