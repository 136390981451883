.loader-div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

#loader {
	animation: fill 0.5s ease forwards 1s infinite;
	animation: fade 2s ease forwards 1.5s infinite;
}
#loader path {
	stroke-dasharray: 1795;
	stroke-dashoffset: 1795;
	animation: line-anim 1s ease forwards infinite;
}

@keyframes line-anim {
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes fade {
	from {
		fill: #a83131;
	}
	to {
		fill: transparent;
	}
}

@keyframes fill {
	from {
		fill: transparent;
	}
	to {
		fill: #a83131;
	}
}
