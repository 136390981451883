.hostel-outer-container {
	position: relative;
	width: 100%;
	height: fit-content;
}

.hostel-card-container {
	display: flex;
	align-items: flex-end;
	padding: 32px 24px;
	background-color: var(--white-color);
	border-radius: 4px;
	border: 1px solid var(--border-stoke);
	transition: 0.3s ease-in-out;
	flex-wrap: wrap;
}

.hostel-card-container:hover {
	box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15),
		0px 0px 1px rgba(9, 30, 66, 0.31);
}

#hostel-svg path {
	transition: 0.3s ease-in-out;
	width: fit-content;
}

.hostel-card-container:hover #hostel-svg path {
	stroke: var(--svg-blue);
}

.hostel-disabled-container {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	border-radius: 4px;
}

.hostel-disabled {
	background-color: var(--neutral-light);
}

.textContainer {
	margin-left: 28.6px;
}

.textContainer h5 {
	font-weight: 700;
	font-size: 1.35rem;
	color: var(--text-color);
}

.textContainer p,
h5 {
	margin: 0px;
	line-height: 0;
}

.hostel-location {
	font-size: 1rem;
	color: var(--light-alt-text-color);
}

.hostel-info p {
	color: var(--light-alt-text-color);
	font-size: 1rem;
}

.hostel-info p:last-child {
	font-size: 0.85rem;
}

.hostel-info-bar {
	color: var(--transparent-grey);
}

.spacer {
	flex: 1;
}

.amount-side h6 {
	font-size: 1.14rem;
	text-align: right;
}

.hostel-card-btn {
	padding: 12px 50px;
	padding-top: 10px;
}

.amount-side span {
	font-size: 1rem;
	color: var(--neutral-30);
	font-weight: 400;
}

@media (max-width: 600px) {
	.hostel-card-container {
		padding: 32px 10px;
	}
}

@media (max-width: 592px) {
	.amount-side {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		flex-direction: column;
	}

	.hostel-card-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

    .textContainer {
        margin-left: 0px;
    }

	.textContainer h5,
	p {
		text-align: center;
	}

	.textContainer h5 {
		margin-top: 20px;
	}

	.hostel-location-container {
		justify-content: center;
		padding-top: 5px;
	}
}
