.jumbotron-container {
	background: #fff;
}
.jumbotron-header > * {
	font-weight: 500;
}

.jumbo-header {
	font-style: normal;
	font-size: 1rem;
	line-height: 20px;
	text-transform: none;
}

/* .jumbotron-content > * {
  margin: 20px 0px;
} */
