.btn_drop_down_container {
	width: fit-content;
}

.btn_dropdown {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.btn_dropdown:disabled {
	cursor: not-allowed;
}

.drop_down_menu {
	width: 191px;
	padding: 8px 0px;
	position: absolute;
	box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
		0px 0px 1px rgba(9, 30, 66, 0.31);
	border-radius: 2px;
	background: var(--white-color);
	margin-top: 10px;
	transform: translateX(-144px);
	left: auto;
	z-index: 1;
}

.drop_down_menu p {
	padding: 10px 16px;
	cursor: pointer;
	transition: 0.3s ease-in-out;
}

.drop_down_menu p:hover {
	opacity: 0.8;
}
