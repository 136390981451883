.auth_container {
	display: flex;
	justify-content: center;
	min-height: 100vh;
	width: 100vw;
	background-color: var(--neutral-10);
	text-align: center;
	align-items: center;
	position: relative;
}

.christmasLightContainer {
	position: absolute;
	z-index: 20;
	top: -30px;
	left: 0;
	width: 100%;
}

.light {
	width: 100%;
}

.auth_card {
	width: 90vw;
	max-width: 552px;
	border: 1px solid var(--border-stoke);
	border-radius: 8px;
	position: relative;
}

.auth_card > div:first-of-type img {
	cursor: pointer;
}
.auth_main_header {
	font-size: 1.5rem;
	color: var(--text-color);
	line-height: 32px;
	margin-top: 2rem;
}

.auth_sub_header {
	font-size: 0.875rem;
	color: var(--text-color);
	line-height: 20px;
	margin-top: 1rem;
}

.auth_input_placeholder::placeholder {
	font-size: 0.875rem;
	color: var(--neutral-80);
}

.auth_label {
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
}
.link_class {
	font-size: 1rem;
	background-color: transparent;
	color: var(--primary);
	font-weight: 450;
	text-decoration: none !important;
}

.logo {
	width: 250px;
	margin: auto;
}

.logo img {
	width: 100%;
}

.otp_input_style {
	border: 1px solid var(--border-stoke);
	border-radius: 4px;
	height: 3.7rem;
	width: 3.7rem !important;
}

.container {
	position: relative;
	background: var(--white-color);
}

.watermark {
	top: 50%;
	left: 50%;
	transform: translate(-50%);
	position: absolute;
	opacity: 0.1;
	z-index: 0;
}
.watermark img {
	width: 600px;
	height: 300px;
	object-fit: contain;
}

.avatar span {
	font-size: 100px;
}

.home_width {
	max-width: 1128px;
	width: calc(100% - 40px);
	margin: auto;
}

.home_width a {
	color: var(--primary);
}
.home_hero {
	height: 260px;
	background: url("https://smcore.blob.core.windows.net/logos/ccu_bg.png");
	background-size: cover;
	display: flex;
	align-items: center;
}

.home_hero > div {
	background-color: var(--home);
	padding: 27px 100px;
	color: var(--white-color);
}

.home_hero > div h1 {
	color: var(--white-color);
}

@media (max-width: 768px) {
	.light {
		top: -15px;
	}

	.background {
		height: 100%;
		padding-bottom: 100px;
		background-color: transparent;
	}
}
