.blue-container {
	background: var(--active-bg-link);
	border-radius: 9px;
	padding: 14px 28px;
}

.blue-container p {
	font-weight: 450;
	font-size: 1rem;
	line-height: 24px;
	margin-bottom: 4px;
}
