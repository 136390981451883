.confetti_container {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--on-surface-dark-low-emphasis);
	animation: show 0.5s linear forwards;
}

.birthday_text {
	z-index: 3;
	width: 80%;
	width: 40%;
	animation: animate 1.5s linear infinite;
	position: relative;
}

@keyframes animate {
	0% {
		transform: scale(1.03);
	}

	50% {
		transform: scale(1);
	}

	80% {
		transform: scale(0.9);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes show {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
