@font-face {
	font-family: "CircularStd";
	src: url("../../assets/fonts/CircularStd-Black.otf"),
		url("../../assets/fonts/CircularStd-Bold.otf"),
		url("../../assets/fonts/CircularStd-Book.otf"),
		url("../../assets/fonts/CircularStd-Medium.otf");
}

.alert_container {
	width: 100%;
	height: 100px;
	font-family: CircularStd;
	background: var(--dark-bg-text-color);
	box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15),
		0px 0px 1px rgba(9, 30, 66, 0.31);
	border-radius: 3px;
}
.alert_text_link {
	color: var(--text-color);
}
