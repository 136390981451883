.mymodal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	background: #fff;
	overflow: auto;
	border-radius: 4px;
	outline: none;
	padding: 20px;
	max-height: 650px;
	overflow-y: scroll;
	max-width: 90%;
	width: fit-content;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.mymodal:focus {
	outline: none !important;
}

.myoverlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(9, 30, 66, 0.54);
	z-index: 9999;
}

.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
	transition: opacity 0.5s;
}
