.aui-nav.aui-nav-breadcrumbs {
	display: flex;
	flex-wrap: wrap;
}

.aui-nav-breadcrumbs > li {
	padding: 0 1px 0 0;
}

.aui-nav-breadcrumbs > li + li:before {
	padding-right: 5px;
}
.aui-nav-breadcrumbs > li a,
.aui-nav-breadcrumbs > li {
	text-decoration: none;
	color: var(--light-text-color);
	font-weight: 500;
}
.aui-nav-breadcrumbs > li a:hover {
	color: var(--primary);
	text-decoration: underline;
	text-underline-offset: 2px;
	text-decoration-thickness: 2px;
}

.aui-nav-breadcrumbs > li > a:focus,
.aui-nav-pagination > li > a:focus {
	box-shadow: none !important;
}
