.toast_container {
	width: 368px;
	height: fit-content;
	padding: 20px 16px;
	background-color: var(--rse-color-background-default);
	border-radius: 3px;
	box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15),
		0px 0px 1px rgba(9, 30, 66, 0.31);

	display: flex;
	margin-right: 20px;

	position: fixed;
	animation: move 0.3s linear forwards;
	top: 80px;
	z-index: 1000;
}

@keyframes move {
	0% {
		right: -100%;
	}
	100% {
		right: 20px;
	}
}

.toast_text_container {
	margin-left: 20px;
}

.toast_container_header,
.toast_container_description {
	font-size: 1rem;
	color: rgba(66, 82, 110, 1);
}

.toast_spacer {
	flex: 1;
}
