.default-header {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 400px;
}
.default-header h5 {
	font-weight: 500;
	font-size: 1.42rem;
	line-height: 24px;
	margin-bottom: 24px;
}

.default-header p {
	font-weight: 450;
	font-size: 1rem;
	line-height: 1.42rem;
	margin-bottom: 24px;
}
