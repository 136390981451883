.personnel_card_align {
	padding: 0px 26px;
}
.info-container {
	margin-top: 9px;
}

.personnel-image {
	width: 173px;
}

.info-avatar span {
	font-size: 100px !important;
}
