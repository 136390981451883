.reu-tabs a {
	display: block;
	color: var(--text-color);
	text-decoration: none;
	font-size: 1rem;
	font-weight: 500;
}

.reu-tabs .top-list-item {
	border-left: 5px solid transparent;
	padding: 0px 4px;
}

.reu-tabs .top-list-item.active-link {
	border-color: var(--primary);
}
.tabs-top-list .active-link {
	color: var(--primary);
}

.tab-disabled {
	color: var(--gray) !important;
	pointer-events: none;
}

.sidebar-hover a:hover {
	color: var(--primary-hover);
}