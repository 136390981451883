.red-tabs ul {
	background-color: white !important;
}

.red-tabs ul li a {
	font-style: normal;
	font-weight: 500;
	font-size: 1rem !important;
	line-height: 16px !important;
	padding: 12px 10px !important;
}

.red-tabs ul li:focus {
	outline: none !important;
	border: none !important;
}

.red-tabs ul li a:focus {
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
}

.disable-tab a {
	color: #a5adba !important;
	cursor: default;
}

.disable-tab a:focus {
	color: #a5adba !important;
}

.rse-tabs
	.aui-tabs.horizontal-tabs
	> .tabs-menu
	> .menu-item.active-tab
	a:before {
	height: 4px !important;
	background-color: var(--rse-unn-color-button-primary-default);
}

.rse-tabs .aui-tabs.horizontal-tabs > .tabs-menu > .menu-item.active-tab a {
	color: var(--rse-unn-color-button-primary-default);
}

.rse-tabs .aui-tabs.horizontal-tabs > .tabs-menu > .menu-item a:hover {
	color: var(--rse-unn-color-button-primary-hover) !important;
}
