.container {
    color: var(--alt-text-color-tint);
    border: 1px dashed var(	--darker-gray);
}

.blueVariant {
    background: var(--active-bg-link);
    border: 1px solid var(--active-bg-link);
}

.container span {
    font-size: 1rem;
}

.paragraph {
    font-size: 0.875rem;
    color: var(--alt-text-color-tint);
}

.blueVariant .paragraph {
    width: 100%;
}