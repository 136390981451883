.dashboard_card {
	padding: 20px;
	border-radius: 8px;
    border: 1px solid var(--neutral-border);
    background-color: var(--white-color);
}


.dahboard_card_title {
    color: var(--darker-gray);
    margin-top: 12px;
}

.dashboard_card_value {
    color: var(--text-color);
    margin-top: 20px;
}