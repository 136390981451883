.hostel-room-container {
	flex-direction: column;
	padding: 16px 20px;
	border-radius: 4px;
	transition: 0.3s ease-in-out;
	border: 1px solid #dfe1e6;
	background-color: var(--white-color);
	cursor: pointer;
	min-height: 116px;
}

.hostel-room-container:hover {
	box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.15),
		0px 0px 1px rgba(9, 30, 66, 0.31);
}

.roomName {
	font-size: 1.14rem;
}

.roomCardDisabled {
	background-color: var(--neutral-light);
	cursor: auto;
}

.roomCardDisabled {
	box-shadow: 0px 0px 0px var(--white-color)!important ;
} 
